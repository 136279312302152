import * as React from "react";
import { Link } from "gatsby";

// styles
const pageStyles = {
	padding: "96px",
	textAlign: "center",
};

const paragraphStyles = {
	marginBottom: 24,
};

// markup
const NotFoundPage = () => {
	return (
		<main style={pageStyles}>
			<p className="title" style={paragraphStyles}>
				Page not found.
			</p>
			<Link to="/">
				<span className="label underline-on-hover">Go Home</span>
			</Link>
		</main>
	);
};

export default NotFoundPage;
